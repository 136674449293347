import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link as GastbyLink } from 'gatsby'

import Button from '../../atoms/Button'
import Link from '../../atoms/Link'
import Image from '../../atoms/Image'
import TravelCounter from '../../atoms/TravelCounter'
import AADLogo from '../../svg/AADLogo'
import Hambuger from '../../svg/Hamburger'
import useProgressBar from '../../hooks/useProgressBar'
import useStickToTop from '../../hooks/useStickToTop'
import { SiteContext } from '../../../Context/site'
import { lang as Lang } from '../../../entities/countryIndexQueries'
import translations  from '../../../entities/translations';
import MenuDownArrow from '../../svg/MenuDownArrow';
import MaxContainer from '../../template/ContainerMax';

import MobileMenuLevel1 from './MobileMenuLevel1'
import { CountryImages, handlePageChange } from './util'

const capeTown = '../../../../travel-capetown.jpg'
const aadLogoHorizintaal = '../../../../AAD-logo-horisontal.png'

const Menu: React.FC = () => {
  const scrollElement = useRef<HTMLDivElement>(null);
  const [stickyClass, setElemntRef] = useStickToTop(scrollElement.current, 'fixed top-0 w-full')
  const [scrollPositionPercentage] = useProgressBar()
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  const [activeMobileMenu, setActiveMobileMenu] = useState<string | null>(null)
  const { state } = useContext(SiteContext);
  const [requests, setRequest] = useState(translations.globals.buttons.requests[state?.lang as Lang])

  useEffect(() => {
    if(state?.lang) {
      setRequest(translations.globals.buttons.requests[state?.lang as Lang])
    }
  }, [state?.lang])

  useEffect(() => {
    if(scrollElement.current?.offsetTop) {
      setElemntRef(scrollElement.current?.offsetTop)
    }
  }, [scrollElement.current?.offsetTop, setElemntRef]);

  return (
    <nav className="">
      <div className="hidden md:block">
        <div className="bg-mortar h-8 flex items-center">
          <div className="w-5/6 mx-auto uppercase text-aths-special text-xs">
            International TOLL-FREE NUMBERS
          </div>
        </div>
      <MaxContainer>
        <header className="py-4 relative">
          <div className="mx-auto flex items-center justify-between">
            <div className="cursor-pointer">
              <GastbyLink to="/">
                <AADLogo />
              </GastbyLink>
            </div>
            <div className="flex font-light text-sm">
              <div className="pr-5">
                <div onMouseOver={() => setActiveMenu('firstMenu')}>
                  <span className="hover:text-orange-red hover:border-orange-red font-normal hover:border-b border-solid pb-1 transition-all cursor-pointer duration-500">
                    {translations.globals.menu.country[state?.lang as Lang]}
                  </span>
                  <div className={`absolute top-28 shadow-lg lg:w-9/12 w-11/12 left-0 right-0 mx-auto px-14 lg:px-20 py-10 bg-white transition-opacity duration-700" ${activeMenu === 'firstMenu' ? 'z-10 opacity-100' : 'opacity-0 -z-50'}`} onMouseLeave={() => setActiveMenu(null)}>
                    <div className="grid grid-cols-4 gap-20 justify-between font-light text-sm">
                      <div className="flex flex-col">
                        <div className="text-sm py-1 border-b font-bold">Länder</div>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Ethiopia</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Botswana</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Ghana</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Kenya</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Lesotho</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Madagascar</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Malawi</Link>
                      </div>
                      <div className="flex flex-col mt-8">
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Mauritius</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Mozambique</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Namibia</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Réunion</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Rwanda</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Zambia</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Seychelles</Link>
                      </div>
                      <div className="flex flex-col mt-8">
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Zimbabwe</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">South Africa</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Eswatini (ehem. Swasiland)</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Tanzania</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Uganda</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Republic of the Congo</Link>
                      </div>
                      <div className="flex flex-col">
                        <div className="font-medium text-sm py-1 text-orange-red border-b">Region list</div>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Kruger National Park</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Cape Town</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Victoria Falls</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Okavango Delta</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Serengeti</Link>
                        <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Masai Mara</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-5">
                <span
                  className="hover:text-orange-red hover:border-orange-red hover:border-b border-solid pb-1 font-normal transition-all cursor-pointer duration-500"
                  onMouseOver={() => setActiveMenu('secondMenu')}>
                    {translations.globals.menu['Our trips'][state?.lang as Lang]}
                </span>
                <div className={`absolute top-28 shadow-lg lg:w-9/12 w-11/12 z-10 left-0 right-0 mx-auto px-14 lg:px-20 py-10 bg-white transition-opacity duration-500" ${activeMenu === 'secondMenu' ? 'z-10 opacity-100' : 'opacity-0 -z-50'}`} onMouseLeave={() => setActiveMenu(null)}>
                  <div className="grid grid-cols-4 gap-20 justify-between font-light text-sm">
                    <div className="flex flex-col">
                      <div className="text-sm py-1 border-b font-bold">Länder</div>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Ethiopia</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Botswana</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Ghana</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Kenya</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Lesotho</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Madagascar</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Malawi</Link>
                    </div>
                    <div className="flex flex-col mt-8">
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Mauritius</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Mozambique</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Namibia</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Réunion</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Rwanda</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Zambia</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Seychelles</Link>
                    </div>
                    <div className="flex flex-col mt-8">
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Zimbabwe</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">South Africa</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Eswatini (ehem. Swasiland)</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Tanzania</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Uganda</Link>
                      <Link type="internal" to="#" className="py-1 border-b hover:text-orange-red font-normal">Republic of the Congo</Link>
                    </div>
                    <div className="flex flex-col">
                      <Link type="internal" to="#" className="py-1">
                        <div className="text-sm py-1 font-semibold text-gray-400">Featured trip</div>
                        <p>Cape Town to Victoria Falls</p>
                        <div>
                          <Image src={capeTown} alt="Featured Trip" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span className="hover:text-orange-red hover:border-orange-red hover:border-b border-solid pb-1 transition-all cursor-pointer duration-500 font-normal"
                onMouseOver={() => setActiveMenu(null)}>
                  {translations.globals.menu['About Us'][state?.lang as Lang]}
                </span>
              </div>
            </div>
            <div className="flex items-center lg:pl-16 xl:pl-24">
              <div className="lg:pr-14 pr-5" onMouseOver={() => setActiveMenu(null)}>
                <Button size="small" color="primary" className="capitalize">{requests}</Button>
              </div>
              {/* <div className="pr-5"><SearchGrey /></div> */}
              <div className="flex pr-5 relative" onMouseOver={() => setActiveMenu('language-switch')} >
                <span className="pr-1 cursor-pointer m-0 uppercase text-sm">{state?.lang}</span>
                <div className="flex items-center justify-center">
                  <MenuDownArrow />
                </div>
                <div className={`absolute top-16 shadow-lg z-10 mx-auto px-5 w-32 py-5
                  bg-white transition-opacity duration-500  ${activeMenu === 'language-switch' ? 'z-10 opacity-100' : 'opacity-0 -z-50'}`}
                  onMouseLeave={() => setActiveMenu(null)}>
                    <div className="font-light text-sm">
                      {state?.allLocales?.map((item, i, arr) => 
                        (<div onClick={() => handlePageChange(item, state)} key={i} 
                        className={`cursor-pointer py-1 ${(arr.length -1) === i ? '' : 'border-b py-2'} 
                        ${state?.lang === item ? 'text-orange-red': ''}  hover:text-orange-red flex justify-between`}>
                          <p className="m-0 uppercase">{item}</p>
                          <Image src={CountryImages[item.toUpperCase()]} alt={item} className="h-5" />
                        </div>))}
                    </div>
                  </div>
              </div>
              <TravelCounter />
            </div>
          </div>
        </header>
      </MaxContainer>

      </div>
      <div className="md:hidden max-h-screen overflow-y-auto">
        <header className="py-4 bg-white relative flex justify-between px-5 items-center max-h-full overflow-y-auto">
          <div className="w-6/12">
            <Image src={aadLogoHorizintaal} alt="AAD Logo" />
          </div>
          <div className="justify-self-end" onClick={() => {setActiveMobileMenu('1')}}>
            <Hambuger />
          </div>
        </header>
        {activeMobileMenu && <MobileMenuLevel1 setActiveMenu={setActiveMobileMenu} />}
      </div>
      <div className={`bg-gainsboro h-1 ${stickyClass} z-50`} id="scroll-bar" ref={scrollElement} >
        <div style={{ width: `${scrollPositionPercentage}%` }} className="bg-orange-red h-1"></div>
      </div>
    </nav>
  )
}

export default Menu;
