import { useState, useCallback } from 'react';
import useListenToScrollEvent from './useListenToScrollEvent';

const useStickToTop = (element: HTMLElement | null, stickyClass: string) => {

  const [_stickyClass, setStickyClass] = useState('');
  const [offSetTop, setOffSetTop] = useState(element?.offsetTop);

  const cb = useCallback(() => {
    if(offSetTop !==0 && window.pageYOffset > offSetTop!) {
      setStickyClass(stickyClass);
    } else {
      setStickyClass('');
    }
  },[setStickyClass, stickyClass, offSetTop]);

  useListenToScrollEvent(cb);
  
  return [_stickyClass, setOffSetTop] as const
}

export default useStickToTop;
