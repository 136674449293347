import React, { useState } from 'react'
import TravelBag from '../../svg/TravelBag_noAdd'


const TravelCounter = () => {
  const [itemCount] = useState<number>(0)

  return (
    <div className="relative">
      <TravelBag />
      <div className="bg-orange-red text-white w-6 h-6 rounded-full flex items-center justify-center absolute right-0 bottom-0 text-xs">{itemCount}</div>
    </div>
  )
}

export default TravelCounter