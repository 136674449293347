import React from 'react'

export default function Facebook() {
  return (
<svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>icon/Twitter</title>
    <g id="icon/Twitter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#D6CAB7">
            <g id="Group-5-Copy-2">
                <path d="M14.2657,0.9338 C6.9137,0.9338 0.9337,6.9138 0.9337,14.2658 C0.9337,21.6168 6.9137,27.5978 14.2657,27.5978 C21.6167,27.5978 27.5977,21.6168 27.5977,14.2658 C27.5977,6.9138 21.6167,0.9338 14.2657,0.9338 M14.2657,28.2808 C6.5367,28.2808 0.2497,21.9938 0.2497,14.2658 C0.2497,6.5378 6.5367,0.2498 14.2657,0.2498 C21.9937,0.2498 28.2817,6.5378 28.2817,14.2658 C28.2817,21.9938 21.9937,28.2808 14.2657,28.2808" id="Fill-1"></path>
                <path d="M14.2657,0.9338 C6.9137,0.9338 0.9337,6.9138 0.9337,14.2658 C0.9337,21.6168 6.9137,27.5978 14.2657,27.5978 C21.6167,27.5978 27.5977,21.6168 27.5977,14.2658 C27.5977,6.9138 21.6167,0.9338 14.2657,0.9338 Z M14.2657,28.2808 C6.5367,28.2808 0.2497,21.9938 0.2497,14.2658 C0.2497,6.5378 6.5367,0.2498 14.2657,0.2498 C21.9937,0.2498 28.2817,6.5378 28.2817,14.2658 C28.2817,21.9938 21.9937,28.2808 14.2657,28.2808 Z" id="Stroke-3" stroke="#D6CAB7" strokeWidth="0.5"></path>
            </g>
            <path d="M20.6535,11.184 C20.2005,11.385 19.7135,11.52 19.2035,11.581 C19.7255,11.269 20.1255,10.775 20.3135,10.185 C19.8255,10.475 19.2855,10.684 18.7095,10.797 C18.2505,10.307 17.5935,10 16.8665,10 C15.4735,10 14.3425,11.131 14.3425,12.524 C14.3425,12.723 14.3635,12.915 14.4075,13.1 C12.3105,12.994 10.4495,11.99 9.2035,10.461 C8.9865,10.835 8.8615,11.269 8.8615,11.732 C8.8615,12.607 9.3075,13.38 9.9855,13.833 C9.5715,13.821 9.1815,13.706 8.8405,13.518 L8.8405,13.549 C8.8405,14.773 9.7115,15.793 10.8665,16.024 C10.6545,16.083 10.4325,16.113 10.2015,16.113 C10.0385,16.113 9.8805,16.098 9.7265,16.068 C10.0485,17.071 10.9795,17.802 12.0855,17.821 C11.2205,18.498 10.1315,18.902 8.9495,18.902 C8.7455,18.902 8.5445,18.891 8.3465,18.867 C9.4645,19.583 10.7915,20.001 12.2165,20.001 C16.8615,20.001 19.4005,16.155 19.4005,12.818 C19.4005,12.708 19.3985,12.599 19.3935,12.491 C19.8875,12.135 20.3165,11.69 20.6535,11.184" id="Fill-5"></path>
        </g>
    </g>
</svg>
  )
}