import React from 'react'

type Prop = {
  className?: string
}
const Index: React.FC<Prop> = ({ children, className='' }) => {
  return (
    <div className={`w-full ${className}`}>
      {children}
    </div>
  )
}

export default Index;
