import React from 'react'

export interface Props {
  text?: string;
  className?: string;
}

export const Index: React.FC<Props & Record<string,any>> = ({ text, className = '', children, ...rest }) => {
  return (
    <q className={`quote-tag text-3xl ${className}`} {...rest}>
      {text || children}
    </q>
  )
}

export default Index;