import { lang as Lang } from '../../../entities/countryIndexQueries'
import translations from '../../../entities/translations';
import { SiteGlobals } from '../../../Context/site'
import { CurrentView } from '../../../entities/templates';
import { SubIdType } from '../../../entities/components';

export const CountryImages = {
  DE: '../../../../country-flag/germany@2x.png',
  EN: '../../../../country-flag/UK@2x.png',
  FR: '../../../../country-flag/france@2x.png',
  ES: '../../../../country-flag/spain@2x.png',
  IT: '../../../../country-flag/italy@2x.png',
} as any;

export const handlePageChange = (el: string, state: SiteGlobals['state']) => {
  const _lang = el as Lang;
  let redirectUrl = '';

  if (el === state?.lang) return

  if (state?.currentView === CurrentView.index) {
    const current = state?.allSlugs && state?.allSlugs[_lang].current
    redirectUrl = `/${_lang}/country/${current}`
  } else {
    const view = translations.CountryPages.find(item => item.template === state?.currentView)
    const current = state?.allSlugs && state?.allSlugs[_lang].current
    if (view) {
      const _slug = view.slug[_lang]
      redirectUrl = `/${_lang}/country/${current}/${_slug}`
    }
  }
  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}

export const getRedirectUrl = (value: string, lang: Lang): string => {
  const countryPage = translations.CountryPages;
  const subMenuTranslation = translations.globals.subMenuTranslation;
  const subMenuArray = Object.values(subMenuTranslation);

  const subMenuObj = subMenuArray.find((item) => item[lang] === value)!;
  const deTrans = subMenuObj?.de! as SubIdType
  const currentPage = countryPage.find(item => item.menu === deTrans);
  return currentPage?.slug[lang as Lang]!
}

export const firstMenu = [
  { name: 'Ethiopia', link: '#' },
  { name: 'Botswana', link: '#' },
  { name: 'Ghana', link: '#' },
  { name: 'Kenya', link: '#' },
  { name: 'Lesotho', link: '#' },
  { name: 'Madagascar', link: '#' },
  { name: 'Malawi', link: '#' },
  { name: 'Mauritius', link: '#' },
  { name: 'Mozambique', link: '#' },
  { name: 'Namibia', link: '#' },
  { name: 'Réunion', link: '#' },
  { name: 'Rwanda', link: '#' },
  { name: 'Zambia', link: '#' },
  { name: 'Seychelles', link: '#' },
  { name: 'Zimbabwe', link: '#' },
  { name: 'South Africa', link: '#' },
  { name: 'Eswatini (ehem. Swasiland)', link: '#' },
  { name: 'Tanzania', link: '#' },
  { name: 'Uganda', link: '#' },
  { name: 'Kruger National Park', link: '#' },
  { name: 'Cape Town', link: '#' },
  { name: 'Victoria Falls', link: '#' },
  { name: 'Okavango Delta', link: '#' },
  { name: 'Serengeti', link: '#' },
  { name: 'Masai Mara', link: '#' }
]

export const mobileCountryList = [
  'ethiopia',
  'botswana',
  'ghana',
  'kenya',
  'lesotho',
  'madagascar',
  'malawi',
  'mauritius',
  'mozambique',
  'namibia',
  'réunion',
  'rwanda',
  'zambia',
  'seychelles',
  'zimbabwe',
  'south africa',
  'eswatini',
  'tanzania',
  'uganda',
  'republic of the congo'
]