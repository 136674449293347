import { CurrentView  } from './templates';
import { locales } from '../../config'
import { SubIdType } from './components'

export type ICurrentView = keyof typeof CurrentView;
type INavigation = 'country' | 'ourTrips' | 'specificTrips' | 'ourPreviousTrips' | 'accomodation' | 'aboutUs'
type IButton = 'readMore' | 'requests' | 'WEITERLESEN' | 'Entdecke weitere'| 'Erfahren Sie hier mehr über uns'
type Menu = 'country' | 'Our trips'| 'About Us';

interface ITranslations {
  CountryPages: {
    slug: {
      [key in locales]: string
    },
    menu: SubIdType,
    template: ICurrentView
  }[],
  globals: {
    buttons: {
      [key in IButton]: { [key in locales]?: string}
    },
    subMenuTranslation: {
      [key in SubIdType]: {
         [key in locales]?: string
      }
    },
    menu: {
      [key in Menu]: {
        [key in locales]?: string
      }
    },
    navigation: {
      [key in INavigation]: {[key in locales]?: string[]}
    }
  }
}

const Translations: ITranslations = {
  CountryPages: [
    {
      slug: {
        de: "uberblick",
        en: "overview",
        es: "overview",
        it: "alloggio"
      },
      menu: 'Überblick',
      template: CurrentView.overview
    },
    {
      slug: {
        de: "unterkunft",
        en: "accommodation",
        es: "alojamiento",
        it: "alloggio"
      },
      menu: 'Unterkünfte',
      template: CurrentView.accommodation
    },
    {
      slug: {
        de: "highlights",
        en: "highlights",
        es: "highlights",
        it: "highlights"
      },
      menu: 'Höhepunkte',
      template: CurrentView.highlights
    },
    {
      slug: {
        de: "regions",
        en: "regions",
        es: "regions",
        it: "regions"
      },
      menu: 'Orte',
      template: CurrentView.regions
    },
    {
      slug: {
        de: "travel-info",
        en: "travel-info",
        es: "travel-info",
        it: "travel-info"
      },
      menu: 'Reiseinfo',
      template: CurrentView["travel-info"]
    },
    {
      slug: {
        de: "trips-previous",
        en: "trips-previous",
        es: "trips-previous",
        it: "trips-previous"
      },
      menu: 'Inspirationen',
      template: CurrentView["trips-previous"]
    },
    {
      slug: {
        de: "trips",
        en: "trips",
        es: "trips",
        it: "trips"
      },
      menu: 'Reisen',
      template: CurrentView.trips
    },
  ],
  globals: {
    buttons: {
      readMore: {
        de: "Weiterlesen",
        en: "Read more",
      },
      requests: {
        de: "Anfragen",
        en: "Requests",
      },
      WEITERLESEN: {
        de: 'WEITERLESEN',
        en: 'CONTINUE READING'
      },
      'Entdecke weitere': {
        de: 'Entdecke weitere',
        en: 'Discover more'
      },
      'Erfahren Sie hier mehr über uns': {
        de: 'Erfahren Sie hier mehr über uns',
        en: 'Find out more about us here'
      }
    },
    menu: {
      country: {
        de: "Reiseländer",
        en: "Country"
      },
      "Our trips": {
        de: "Reisen",
        en: "Our trips"
      },
      "About Us": {
        de: "Über uns",
        en: "About Us"
      }
    },
    subMenuTranslation: {
      Höhepunkte: {
        de: 'Höhepunkte',
        en: 'Highlights'
      },
      Unterkünfte: {
        de: 'Unterkünfte',
        en: 'Accomodation'
      },
      Reiseinfo: {
        de: 'Reiseinfo',
        en: 'Travel info'
      },
      Inspirationen: {
        de: "Inspirationen",
        en: 'Previous trips'
      },
      Reisen: {
        de: 'Reisen',
        en: 'Our trips'
      },
      Orte: {
        de: 'Orte',
        en: 'Regions'
      },
      Überblick: {
        de: 'Überblick',
        en: 'Overview'
      },
      Reiseinformationen: {
        de: 'Reiseinformationen',
        en: 'Travel information'
      }
    },
    navigation: {
      country: {
        de: [
          'Überblick', 'Höhepunkte', 'Orte', 'Reisen',
          'Inspirationen', 'Unterkünfte', 'Reiseinfo'
        ],
        en: [
          "Overview", "Highlights", "Regions", "Our trips",
         "Previous trips", "Accomodation", "Travel info"
        ]
      },
      ourTrips: {
        de: [
          'Reisen', 'Inspirationen', 'Unterkünfte', 'Reiseinfo'
        ],
        en: [
          "Our trips", "Previous trips", "Accomodation", "Travel info"
        ]
      },
      specificTrips: {
        de: [
          'Überblick', 'Tourverlauf', 'Leistungen', 'Preise'
        ],
        en: [
          "Overview", "Tour itinerary", "Services", "Price"
        ]
      },
      ourPreviousTrips: {
        de: [
          'Überblick', 'Tourverlauf', 'Leistungen', 'Preise'
        ],
        en: [
          "Overview", "Tour itinerary", "Services", "Price"
        ]
      },
      accomodation: {
        de: [
          'Lage', 'Unterkunft', 'Zimmer', 'Aktivitaten', 'Preiskategorie'
        ],
        en: [
          "Location", "Accomodation", "Tour Room", "Activites", "Price"
        ]
      },
      aboutUs: {
        de: [
          'Wer wir sind', 'Was wir machen', 'Untere Mission', 'Untere Philosophie', 'Untere Team'
        ],
        en: [
          "Who we are", "What we do", "Our mission", "Our philosophy", "Our team"
        ]
      },
    }
  }
}

export default Translations;
