import React, { useState, Dispatch, useEffect } from 'react'
import Close from '../../svg/Close'
import Back from '../../svg/Back'
import Text from '../../atoms/Text'
import Level3 from './MobileMenuLevel3'

import { mobileCountryList } from './util'

export interface ILevel2 {
  setActiveMenu: Dispatch<string | null>,
  activeMenu: string | null,
  setPreviousMenu: Dispatch<string | null>
};

const Level2: React.FC<ILevel2> = ({ setActiveMenu, activeMenu, setPreviousMenu }) => {

  const [selectedMenu, setselectedMenu] = useState<string | null>(null)
  const [className, setclassName] = useState('animate__slideInRight')

  const closeMenu = () => {
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(null)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
  }, [])

  return (
    <div className={`fixed z-40 right-0 bottom-0 top-0 left-8 bg-white shadow-left py-6 animate__animated ${className} animate__faster`}>
      <div>
        <div className="flex justify-between px-5 capitalize mb-4" onClick={closeMenu}>
          <Text type="P" ptype="BASE" className="text-2xl font-medium mb-0">
            {activeMenu }
          </Text>
          <Close />
        </div>
        <div className="flex justify-between items-center bg-granite-green px-5 font-medium text-base" onClick={closeMenu}>
          <Text type="P" ptype="BASE" className="text-xl py-2 white-text mb-0">
            Back
          </Text>
          <div className="transform rotate-180 flex items-center">
            <Back fill="#ffffff" opacity="1" />
          </div>
        </div>
        <div className="w-10/12 mx-auto py-2 max-h-85 overflow-y-auto">
          {mobileCountryList.map(list => (
            <div key={list} className="flex items-center border-b border-gray-200 py-4 cursor-pointer" onClick={() => setselectedMenu(list)}>
              <Back />
              <Text type="P" ptype="BASE" className="text-lg pl-4 mb-0 capitalize">
                {list}
              </Text>
            </div>
          ))}
        </div>
        {selectedMenu && <Level3 activeMenu={selectedMenu} setActiveMenu={setselectedMenu} setPreviousMenu={setActiveMenu} setFirstMenu={setPreviousMenu} />}
      </div>
    </div>
  )
}

export default Level2