import React, { useContext } from 'react'
import Wrapper from '../../template/ContainerMax';
import Text from '../../atoms/Text';
import { SiteContext } from '../../../Context/site';
import PortableText from '../../sanityBlockContents/PortableText';

const Index = () => {
  const { state } = useContext(SiteContext);

  return (
    <div className="w-full h-large overflow-hidden bg-center bg-cover bg-no-repeat"
    style={{'backgroundImage': `url(${state?.landingPageImage?.image?.asset?.url})`}}>
      <Wrapper className="h-full flex items-end">
        <div className="text-white">
          <Text type="H1" className="white-text">{state?.countryName}</Text>
          {state?.subTitleData && <PortableText className="text-lg font-semibold" content={state?.subTitleData} template={{}} />}
        </div>
      </Wrapper>
    </div>
  )
}

export default Index;
