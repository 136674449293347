import React from 'react'

type ListComponent = React.FunctionComponent<Prop>;
type Component = React.FunctionComponent & {
  UL: ListComponent,
  LI: ListComponent,
}

type Prop = {
  className?: string;
}

const List: Component = ({ children }) => (<>{children} </>);

const UL: ListComponent  = ({ children, className = '' }) => (
  <ul className={`flex m-0 ${className}`}>
    {children}
  </ul>
)

const LI: ListComponent  = ({ children, className = '' }) => (
  <li className={`m-0 mx-2 ${className}`}>
    {children}
  </li>
)

List.UL = UL;
List.LI = LI;

export default List;
