import React from 'react'

import H1 from '../Typograph/H1'
import H2 from '../Typograph/H2'
import H3 from '../Typograph/H3'
import H4 from '../Typograph/H4'
import H5 from '../Typograph/H5'
import P, { PType } from '../Typograph/P'
import Quote from '../Typograph/Quote'

export type IText =  {
  type: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'Quote';
  text?: string;
} | {
  type : 'P'
  text?: string;
  ptype: PType;
}

export const Text: React.FC<IText & Record<string,any>> = ({
  type,
  text,
  ptype,
  children,
  ...rest 
}) => {

  const TextTypes = {
    'H1': H1,
    'H2': H2,
    'H3': H3,
    'H4': H4,
    'H5': H5,
    'P': P,
    'Quote': Quote,
  }

  const Component = TextTypes[type] ? TextTypes[type] : TextTypes['P'];

  return <Component text={text} ptype={ptype} {...rest}>{children}</Component>;
}

export default Text;
