import React from 'react'

export interface Props {
  text?: string;
  className?: string;
}

export const Index: React.FC<Props & Record<string,any>> = ({ text, children, className = '', ...rest }) => {
  return (
    <h5 className={`text-xl font-semibold align-baseline font-sans ${className}`} {...rest}>
      {text || children}
    </h5>
  )
}

export default Index;