import React from 'react'

type Prop = {
  className?: string
}
const Index: React.FC<Prop> = ({ children, className='' }) => {
  return (
    <div className={`max-w-screen-xxl xl:w-10/12 w-11/12 mx-auto ${className}`}>
      {children}
    </div>
  )
}

export default Index;
