import React, { useState, useRef, useEffect, useContext } from 'react'

import Wrapper from '../../template/ContainerMax';
import Text from '../../atoms/Text';
import List from '../../atoms/List';
import Link from '../../atoms/Link';
import Button from '../../atoms/Button';
import TravelCounter from '../../atoms/TravelCounter';
import ListIcon from '../../svg/List';
import useStickToTop from '../../hooks/useStickToTop';
import { SiteContext } from '../../../Context/site';
import { SubIdType } from '../../../entities/components';
import translations  from '../../../entities/translations';
import { lang } from '../../../entities/countryIndexQueries';
import { CurrentView } from '../../../entities/templates';
import { getRedirectUrl } from '../Menu/util';

const Index: React.FC = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [stickyCss, setElemntRef] = useStickToTop(elementRef.current, 'fixed top-0 w-full');
  const { state } = useContext(SiteContext);
  const [requests, setRequest] = useState(translations.globals.buttons.requests[state?.lang as lang]);
  const [subMenus, setSubMenu] = useState<SubIdType[]>(translations.globals.navigation.country[state?.lang as lang] as SubIdType[]);
  const [menuActive, setmenuActive] = useState<boolean>(false)
  const [activeSubMenu, setActiveMenu] = useState<string>('');

  useEffect(() => {
    if(state?.lang) {
      setRequest(translations.globals.buttons.requests[state?.lang as lang]);
      setSubMenu(translations.globals.navigation.country[state?.lang as lang] as SubIdType[])

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.lang])

  useEffect(() => {
    if(elementRef.current?.offsetTop) {
      setElemntRef(elementRef.current?.offsetTop)
    } 
  }, [elementRef.current?.offsetTop, setElemntRef]);  


  useEffect(() => {
    subMenus && setActiveMenu(subMenus[1])
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMenus])

  const handleMenuSelected = (item: string) => {
    setActiveMenu(item)
    setmenuActive(false)
  }

  const getUrl = (value = '') => {
    const url = value ? getRedirectUrl(value, state?.lang as lang) : ''
    return `/${state?.lang}/country/${state?.allSlugs && state?.allSlugs[state?.lang as lang].current}/${url}`
  }

  return (
    <div className={`shadow-xss bg-white z-30 ${stickyCss}`} ref={elementRef} >
      <Wrapper>
        <div className="h-16 text-granite-green flex items-center justify-between relative">
          <div className="flex items-center lg:justify-start justify-between text-sm">
            <Text type="P" ptype="BASE" className="font-semibold m-0 mr-1">
              <Link type="internal" to={getUrl()} >
                <span className="text-payne-gray">{`${state?.countryName} |`}</span>
              </Link>
            </Text>
            <div className="lg:hidden flex justify-between items-center">
              <List>
                <List.UL>
                  <List.LI className="no-margin">{activeSubMenu}</List.LI>
                </List.UL>
              </List>
            </div>
            <div className="lg:block hidden text-payne-gray">
              <List>
                <List.UL>
                  {subMenus?.map((item, index) => <List.LI key={index}>
                    {
                      state?.currentView === CurrentView.index ?
                      (<Link type="internal" to={`#${item}`} className={item === activeSubMenu ? 'font-bold' : ''} onClick={() => setActiveMenu(item)}>{item}</Link>) : 
                      (<Link type="internal" to={getUrl(item)}>{item}</Link>)
                    }
                  </List.LI>)}
                </List.UL>
              </List>
            </div>
          </div>
          <div className="lg:flex hidden items-center">
            <div className="mr-4">
              <Button size="small" color="primary" className="capitalize">{requests}</Button>
            </div>
            <div>
              <TravelCounter />
            </div>
          </div>
          <div className="lg:hidden" onClick={() => setmenuActive((prev) => !prev)}>
            <ListIcon />
          </div>
          {menuActive && (
            <section className="absolute z-20 bg-white top-18 left-10 right-0 shadow-bottomLeft py-5 animate__animated animate__fadeIn animate__faster">
              <div className="w-11/12 mx-auto">
                <List.UL className="block-items">
                  {subMenus?.map((item, index) => item !== activeSubMenu && state?.currentView === CurrentView.index ?
                  <List.LI key={index} className={index < (subMenus.length - 1) ? 'mb-3 border-b pb-1': ''}>
                      <Link type="internal" to={`#${item}`} className={item === activeSubMenu ? 'font-bold' : ''} onClick={() => handleMenuSelected(item)}>{item}</Link>
                    </List.LI> : 
                    <List.LI key={index} className={index < (subMenus.length - 1) ? 'mb-3 border-b pb-1': ''}>
                      <Link type="internal" to={getUrl(item)} className={item === activeSubMenu ? 'font-bold' : ''}>{item}</Link>
                    </List.LI>
                  )}
                </List.UL>
              </div>
            </section>
          )}
        </div>
      </Wrapper>
    </div>
  )
}

export default Index;
