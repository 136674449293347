import React from 'react'

const List: React.FC = () => {
  return (
    <svg width="25px" height="5px" viewBox="0 0 25 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/list/gold</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mobile-navigation-–-Montserrat" transform="translate(-391.000000, -1814.000000)" fill="#8C7E64" fillRule="nonzero">
          <g id="iPhone-Home" transform="translate(32.000000, 1240.000000)">
            <g id="Secondary-navigation" transform="translate(29.000000, 544.000000)">
              <g id="icon/list/gold" transform="translate(330.000000, 30.000000)">
                <circle id="Oval" cx="12.5" cy="2.5" r="2.5"></circle>
                <circle id="Oval" cx="2.5" cy="2.5" r="2.5"></circle>
                <circle id="Oval" cx="22.5" cy="2.5" r="2.5"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default List;
