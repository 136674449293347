import React, { Dispatch, useState, useContext, useEffect } from 'react'
import Close from '../../svg/Close'
import Back from '../../svg/Back'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import { SubIdType, } from '../../../entities/components';
import translations from '../../../entities/translations';
import { SiteContext } from '../../../Context/site';
import { lang } from '../../../entities/countryIndexQueries';

export interface ILevel3 {
  setActiveMenu: Dispatch<string | null>,
  activeMenu: string | null,
  setPreviousMenu: Dispatch<string | null>,
  setFirstMenu: Dispatch<string | null>
};

const Level3: React.FC<ILevel3> = ({ setActiveMenu, activeMenu, setPreviousMenu, setFirstMenu }) => {
  const { state } = useContext(SiteContext);

  const [subMenu, setSubMenu] = useState<SubIdType[]>(translations.globals.navigation.country[state?.lang as lang] as SubIdType[]);
  const [className, setclassName] = useState('animate__slideInRight')

  const closeMenu = () => {
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(null)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
  }, [])

  useEffect(() => {
    if(state?.lang ) {
      setSubMenu(translations.globals.navigation.country[state?.lang as lang] as SubIdType[])
    }
  }, [state?.lang ]);

  return (
    <div className={`fixed z-50 right-0 bottom-0 top-0 left-6 bg-white shadow-left py-6 animate__animated ${className} animate__faster`}>
      <div>
        <div className="flex justify-between px-5 capitalize mb-4" onClick={closeMenu}>
          <Text type="P" ptype="BASE" className="text-2xl font-medium mb-0">
            {activeMenu}
          </Text>
          <Close />
        </div>
        <div className="flex justify-between items-center bg-granite-green px-5 font-medium text-base" onClick={closeMenu}>
          <Text type="P" ptype="BASE" className="text-xl py-2 white-text mb-0">
            Back
          </Text>
          <div className="transform rotate-180 flex items-center">
            <Back fill="#ffffff" opacity="1" />
          </div>
        </div>
        <div className="w-10/12 mx-auto py-2 max-h-85 overflow-y-auto">
          {subMenu.map(item => <Link type="internal" to={`#${item}`} key={item} className="flex items-center border-b border-gray-200 py-4"
            onClick={() => {
              setPreviousMenu(null)
              setFirstMenu(null)
            }}>
            <Text type="P" ptype="BASE" className="text-lg pl-4 mb-0">
              {item}
            </Text>
          </Link>)}
        </div>
      </div>
    </div>
  )
}

export default Level3