import React from 'react'

import AccreditedPartners, { IAccreditedPartners } from '../../molecule/AccreditedPartners'
import FooterStandalone, { IFooterStandalone } from '../../molecule/FooterStandalone'

export interface IFooter extends IAccreditedPartners, IFooterStandalone {}

const Footer: React.FC<IFooter & Record<string, any>> = ({ imageData, footerLists }) => {
  return (
    <>
      <AccreditedPartners imageData={imageData} />
      <FooterStandalone footerLists={footerLists} />
    </>
  )
}

export default Footer