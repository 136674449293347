import React from 'react'

export default function Back ({ fill = '#8C7E64', opacity = '0.49880827' }: any) {
  return (
    <svg width="22px" height="18px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/back</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={opacity}>
        <g id="Mobile-navigation-–-Montserrat" transform="translate(-626.000000, -332.000000)" fill={fill} fillRule="nonzero">
          <g id="iPhone-menu-level-2-Copy" transform="translate(505.000000, 150.000000)">
            <g id="iPhone-mockup-1-Copy-2" transform="translate(29.000000, 25.000000)">
              <g id="icon/back" transform="translate(92.000000, 157.000000)">
                <path d="M18.5614035,7.20010726 L2.46180702,7.20010726 L8.62661404,1.3793782 C8.94942105,1.07458192 8.96192982,0.568188088 8.65445614,0.248191989 C8.34738596,-0.0714041161 7.83654386,-0.0842039601 7.51333333,0.220592325 L0.472912281,6.8685113 C0.168263158,7.17090761 0,7.57250272 0,8.00009751 C0,8.4272923 0.168263158,8.8292874 0.487035088,9.14488355 L7.51373684,15.7792027 C7.66989474,15.9268009 7.87003509,16 8.07017544,16 C8.28322807,16 8.4962807,15.916801 8.65485965,15.751603 C8.96233333,15.4316069 8.94982456,14.9256131 8.62701754,14.6208168 L2.43638596,8.80008776 L18.5614035,8.80008776 C19.0068772,8.80008776 19.3684211,8.44169212 19.3684211,8.00009751 C19.3684211,7.55850289 19.0068772,7.20010726 18.5614035,7.20010726 L18.5614035,7.20010726 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
