import React, { HTMLAttributes } from 'react'

export interface ImageInt extends HTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  className?: string
}

export const Image: React.FC<ImageInt> = ({ src, alt, className, ...rest }) => (
  <img src={src} alt={alt} {...rest} className={`inline-block mb-0 ${className}`} />
)

export default Image;
