import React from 'react'

export default function Close() {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/close</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Mobile-navigation-–-Montserrat" transform="translate(-390.000000, -224.000000)" stroke="#000000" strokeWidth="2">
          <g id="iPhone-menu-level-1-Copy" transform="translate(33.000000, 150.000000)">
            <g id="Menu-expanded-level-1" transform="translate(29.000000, 25.000000)">
              <g id="Group-8" transform="translate(329.000000, 50.000000)">
                <line x1="-3" y1="9.5" x2="22" y2="9.5" id="Line-2" transform="translate(9.500000, 9.500000) rotate(45.000000) translate(-9.500000, -9.500000) "></line>
                <line x1="-3" y1="9.5" x2="22" y2="9.5" id="Line-2-Copy" transform="translate(9.500000, 9.500000) rotate(-45.000000) translate(-9.500000, -9.500000) "></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
