import React, { useState, useContext, Dispatch, useEffect } from 'react'
import Close from '../../svg/Close'
import SearchGrey from '../../svg/SearchGrey'
import Back from '../../svg/Back'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import Button from '../../atoms/Button'
import TravelCounter from '../../atoms/TravelCounter'
import Image from '../../atoms/Image'
import { SiteContext } from '../../../Context/site'
import { lang as Lang } from '../../../entities/countryIndexQueries'
import translations  from '../../../entities/translations';

import Level2 from './MobileMenuLevel2'
import LanguageMenu from './LanguageMenu'
import { CountryImages } from './util'

export interface ILevel1 {
  setActiveMenu: Dispatch<string | null>
};

const Level1: React.FC<ILevel1> = ({ setActiveMenu }) => {
  const { state } = useContext(SiteContext);
  const [activeMenu, setactiveMenu] = useState<string | null>(null)
  const [languageMenuActive, setLanguageMenu] = useState<boolean | null>(null)
  const [requests, setRequest] = useState(translations.globals.buttons.requests[state?.lang as Lang])
  const [className, setclassName] = useState('animate__slideInRight')

  const closeMenu = () => {
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(null)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
  }, [])
 

  useEffect(() => {
    if(state?.lang) {
      setRequest(translations.globals.buttons.requests[state?.lang as Lang])
    }
  }, [state?.lang])
  
  return (
    <div className={`fixed z-30 right-0 bottom-0 top-0 left-8 bg-white shadow-left py-6 animate__animated ${className} animate__faster`}>
      <div className="flex items-center justify-end w-full px-5 capitalize mb-4" onClick={closeMenu}>
        <Close />
      </div>
      <div className="w-10/12 mx-auto">
        <div className="my-10 flex justify-between rounded-full border border-granite-green pl-4">
          <input type="text" className="flex-1 focus:outline-none rounded-full text-payne-gray" placeholder="Search for your trip" />
          <div className="bg-granite-green py-3 px-4 rounded-r-full">
            <SearchGrey fill="#ffffff" />
          </div>
        </div>
        <div>
          <div className="flex items-center border-b border-gray-200 py-4 cursor-pointer" onClick={() => setactiveMenu('Reiseländer')}>
            <Back />
            <Text type="P" ptype="BASE" className="text-xl pl-4 mb-0">
              {translations.globals.menu.country[state?.lang as Lang]}
            </Text>
          </div>
          <Link type="internal" to="" className="flex items-center border-b border-gray-200 py-4 text-base">
            <Back />
            <Text type="P" ptype="BASE" className="text-xl pl-4 mb-0">
              {translations.globals.menu['Our trips'][state?.lang as Lang]}
            </Text>
          </Link>
          <Link type="internal" to="" className="flex items-center border-b border-gray-200 py-4">
            <Back />
            <Text type="P" ptype="BASE" className="text-xl pl-4 mb-0">
              {translations.globals.menu['About Us'][state?.lang as Lang]}
            </Text>
          </Link>
        </div>
        <div className="my-14 flex justify-center">
          <Button size="large" color="primary" className="py-3 inline-block w-full capitalize font-medium">{requests}</Button>
        </div>
        <div className="my-16">
          <Link type="internal" to="" className="flex items-end border-b border-gray-200 pb-3">
            <TravelCounter />
            <Text type="P" ptype="BASE" className="text-xl pl-4 mb-0">
              Reisetasche
            </Text>
          </Link>
          <div className="flex items-center justify-between border-b border-gray-200 py-3" onClick={() => setLanguageMenu(true)}>
            <Back />
            <Text type="P" ptype="BASE" className="text-xl pl-4 mb-0">
              Change language
            </Text>
            {state?.lang && <Image src={CountryImages[state?.lang.toUpperCase()]} alt={state?.lang as string} className="h-5" />}
          </div>
        </div>
        <div className="my-7 flex justify-center">
          <Button size="large" color="secondary" className="py-3 inline-block w-full" style={{ backgroundColor: '#0A0A0A' }}>Log into profile</Button>
        </div>
      </div>
      {activeMenu && <Level2 setActiveMenu={setactiveMenu} activeMenu={activeMenu} setPreviousMenu={setActiveMenu} />}
      {languageMenuActive && <LanguageMenu setActiveMenu={setLanguageMenu} />}
    </div>
  )
}

export default Level1