import React from 'react'

export interface Props {
  text?: string;
  className?: string;
}

export const Index: React.FC<Props & Record<string,any>> = ({ text, className = '', children, ...rest }) => {
  return (
    <h1 className={`text-4xl font-normal align-baseline font-sans ${className}`} {...rest}>
      {text || children}
    </h1>
  )
}

export default Index;