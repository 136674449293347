import React from 'react'

export default function Facebook() {
  return (
    <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/Facebook</title>
      <g id="icon/Facebook" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#D6CAB7">
          <g id="Group-5" stroke="#D6CAB7" strokeWidth="0.5">
            <path d="M14.2657,0.9338 C6.9137,0.9338 0.9337,6.9138 0.9337,14.2658 C0.9337,21.6168 6.9137,27.5978 14.2657,27.5978 C21.6167,27.5978 27.5977,21.6168 27.5977,14.2658 C27.5977,6.9138 21.6167,0.9338 14.2657,0.9338 Z M14.2657,28.2808 C6.5367,28.2808 0.2497,21.9938 0.2497,14.2658 C0.2497,6.5378 6.5367,0.2498 14.2657,0.2498 C21.9937,0.2498 28.2817,6.5378 28.2817,14.2658 C28.2817,21.9938 21.9937,28.2808 14.2657,28.2808 Z" id="Stroke-3"></path>
          </g>
          <path d="M14.9925784,22 L14.9925784,14.4899436 L17.4701588,14.4899436 L17.8616198,12.0011906 L14.9925784,12.0011906 L14.9925784,10.7532635 C14.9925784,10.103872 15.2054417,9.4840331 16.1373721,9.4840331 L18,9.4840331 L18,7 L15.3556406,7 C13.1322887,7 12.5255838,8.46436256 12.5255838,10.4942213 L12.5255838,12.0000425 L11,12.0000425 L11,14.4899436 L12.5255838,14.4899436 L12.5255838,22 L14.9925784,22 Z" id="Fill-4"></path>
        </g>
      </g>
    </svg>
  )
}
