import React, { useContext, Dispatch, useState, useEffect } from 'react'
import Close from '../../svg/Close'
import Back from '../../svg/Back'
import Text from '../../atoms/Text'
import { CountryImages, handlePageChange } from './util'
import Image from '../../atoms/Image'

import { SiteContext } from '../../../Context/site'


export interface ILanguageMenu {
  setActiveMenu: Dispatch<boolean | null>,
};

const LanguageMenu: React.FC<ILanguageMenu> = ({ setActiveMenu }) => {
  const { state } = useContext(SiteContext)
  const [className, setclassName] = useState('animate__slideInRight')

  const closeMenu = () => {
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setActiveMenu(null)
    }, 500);
  }

  useEffect(() => {
    setclassName('animate__slideInRight')
  }, [])
  
  return (
    <div className={`fixed z-40 right-0 bottom-0 top-0 left-8 bg-white shadow-left py-6 animate__animated ${className} animate__faster`}>
      <div className="flex items-center justify-end w-full px-5 capitalize mb-5" onClick={closeMenu}>
        <Close />
      </div>
      <div className="flex justify-between items-center bg-granite-green px-5 font-medium text-base" onClick={closeMenu}>
        <Text type="P" ptype="BASE" className="text-xl py-2 white-text mb-0">
          Back
        </Text>
        <div className="transform rotate-180 flex items-center">
          <Back fill="#ffffff" opacity="1" />
        </div>
      </div>
      <div className="w-10/12 mx-auto py-2">
        <div className="flex items-center justify-between">
          <div className="w-full py-2 max-h-85 overflow-y-auto">
            {state?.allLocales?.map((language, i) => (
              <div className="uppercase border-b border-gray-200 py-2 flex justify-between items-center" key={i} onClick={() => handlePageChange(language, state)}>
                <Text type="P" ptype="BASE" className={`text-xl pl-4 mb-0 ${language === state?.lang && 'text-orange-red'}`}>
                  {language}
                </Text>
                <Image src={CountryImages[language.toUpperCase()]} alt={language} className="h-5" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageMenu