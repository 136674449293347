import React from 'react'

export default function Hamburger() {
  return (
    <svg width="27px" height="19px" viewBox="0 0 27 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/hamburger menu</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g id="Mobile-navigation-–-Montserrat" transform="translate(-394.000000, -1315.000000)" stroke="#000000" strokeWidth="2">
          <g id="iPhone-Home" transform="translate(32.000000, 1240.000000)">
            <g id="iPhone-mockup-1" transform="translate(29.000000, 25.000000)">
              <g id="Mobi-top-menu" transform="translate(-216.000000, -56.000000)">
                <g transform="translate(216.000000, 0.000000)">
                  <g id="icon/hamburger-menu" transform="translate(334.000000, 107.000000)">
                    <line x1="0" y1="0.5" x2="25" y2="0.5" id="Line-2"></line>
                    <line x1="0" y1="8.5" x2="25" y2="8.5" id="Line-2-Copy"></line>
                    <line x1="0" y1="16.5" x2="14.3961" y2="16.5" id="Line-2-Copy-2"></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
