import React, { useState } from 'react'

export type PType = 'BASE'| 'SM_BOLD' | 'SM_LIGHT'

export interface Props {
  text?: string;
  ptype: PType,
  className?: string;
}

export const Index: React.FC<Props & Record<string,any>> = ({ text, ptype, className = '', children, ...rest }) => {
  const [_className] = useState(() => {
    const _classNameObj: Record<PType, string> = {
      BASE: 'base-p-tag',
      SM_BOLD: 'sm-bold-p-tag ',
      SM_LIGHT: 'sm-light-p-tag'
    }
    return _classNameObj[ptype]
  })

  return (
    <p className={`${className} ${_className}`} {...rest}>
      {text || children}
    </p>
  )
}

export default Index;
