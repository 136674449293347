import React from 'react'
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import Wrapper from '../../template/ContainerMax'

export interface IAccreditedPartners {
  imageData: {
    src: string | any;
    alt: string;
  }[]
}

const AccreditedPartners: React.FC<IAccreditedPartners & Record<string, any>> = ({ imageData }) => (

  <section className="bg-white-smoke">
    <Wrapper>
      <div className="mx-auto py-8">
        <Text text="Accredited partners" type="H5" className="text-granite-green text-xl pb-4" />
        <div className="grid xl:grid-cols-9 gap-x-16 gap-y-12 lg:grid-cols-8 md:grid-cols-6 grid-cols-3">
          {imageData.map((image, index) =>
            <div className="flex items-center" key={index}>
              <Image
                src={`${image.src}`}
                alt={`${image.alt}`}/>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  </section>
)

export default AccreditedPartners