import React from 'react';

type buttonType = 'submit' | 'button' | 'reset';
type buttonSize = 'small'| 'large';
type buttonColor = 'primary'| 'secondary';

export interface IButton {
  type?: buttonType,
  className?: string,
  size: buttonSize,
  color: buttonColor,
  style?: any,
};

const _size = {
  small: 'px-12',
  large: 'px-20',
}

const _color = {
  primary: 'bg-orange-red',
  secondary: 'bg-granite-green',
}

const Button: React.FC<IButton> = ({ 
  type = 'button',
  children,
  className = '', 
  color = 'primary', 
  size = 'small', 
  ...rest 
  }) => {
 
  return (
    <>
      <button
        type={type}
        className={`py-2 rounded-full text-sm text-white focus:outline-none
       ${_size[size]}
       ${_color[color]}
       ${className}`}
       {...rest}>
         { children }
      </button>
    </>
  )
};

export default Button;
