import React from 'react'

export interface Props {
  text?: string;
  className?: string;
}

export const Index: React.FC<Props & Record<string,any>> = ({ text, children, className = '', ...rest }) => {
  return (
    <h2 className={`text-3xl font-normal align-baseline font-sans ${className}`} {...rest}>
      {text || children}
    </h2>
  )
}

export default Index;