import { useEffect } from 'react'

const useListenToScrollEvent = (cb: Function) => {
  useEffect(() => {
    const handleScrollEvent = () => {
      requestAnimationFrame(() => {
        cb()
      })
    }
    const listenToScrollEvent = () => {
      document.addEventListener("scroll", () => {
        handleScrollEvent()
      })
    }
    listenToScrollEvent();

    return () => {
      document.removeEventListener("scroll", () => {
        handleScrollEvent()
      })
    }
  }, [cb])
}

export default useListenToScrollEvent;
