import React from 'react'
import SEO from "../../seo";
import { Meta } from "../../../entities/seo";
import Menu from '../../molecule/Menu';
import Footer from '../../organism/Footer';
import { data } from '../../organism/Footer/data';
import HeroImage from '../../molecule/HeroImage';
import SubMenu from '../../molecule/SubMenu';
import Wrapper from '../ContainerFull';

type Props = {
  seoTitle: string
  meta?: Meta,
  showSubMenu?: boolean
}

const Index: React.FC<Props> = ({ children, seoTitle, meta = [] }) => {
  return (
    <>
        <Menu />
        <SEO title={seoTitle} meta={meta} />
        <HeroImage />
        <SubMenu />
        <Wrapper>
          {children}
        </Wrapper>
        <Footer footerLists={data.footerLists} imageData={data.imageData} />
    </>
  )
}

export default Index;
