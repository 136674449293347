import { locales } from "../../config";

export enum CurrentView {
  index = 'index',
  accommodation = 'accommodation',
  highlights = 'highlights',
  regions = 'regions',
  overview = 'overview',
  'travel-info' = 'travel-info',
  'trips' = 'trips',
  'travel' = 'travel-info',
  'trips-previous' = 'trips-previous',
}

export type Slugs = {[key in locales]: { current: string }}