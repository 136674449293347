import React from 'react'
import { Link as GLink } from 'gatsby'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'

import Phone from '../../svg/Phone'
import Email from '../../svg/Email'
import Facebook from '../../svg/Facebook'
import Youtube from '../../svg/Youtube'
import Twitter from '../../svg/Twitter'
import Wrapper from '../../template/ContainerMax'

interface ILink {
  text: string;
  to: string;
}

export interface IFooterStandalone {
  footerLists: { title: string, links: ILink[] }[]
}

const date = new Date()
const year = date.getFullYear()

const FooterStandalone: React.FC<IFooterStandalone & Record<string, any>> = ({ footerLists }) => {
  return (
    <footer className="text-aths-special">
      <section className="bg-payne-gray py-10">
        <Wrapper>
          <div className="mx-auto grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 justify-between text-sm font-light leading-7 gap-y-5 md:gap-y-10 lg:gap-y-0">
            {footerLists.map((footerList, index) => (
              <div key={index}>
                <Text text={footerList.title} type="P" ptype="BASE" className="font-semibold mb-2 text-base" />
                {footerList.links.map((link, linkIndex) => (
                  <GLink to={link.to} className="block hover:font-normal hover:text-white transition-colors duration-300 font-normal" key={linkIndex}>{link.text}</GLink>
                ))}
            </div>
            ))}
            <div className="md:flex lg:block md:justify-between md:col-span-4 col-span-2 mt-8 sm:mt-0 lg:col-span-1">
              <div className="mb-10">
                <Link to="tel:+000000000" type="external" className="flex mb-5 hover:text-white transition-colors duration-300 font-normal text-aths-special">
                  <Phone />
                  <Text text="+00 00 000 0000" type="P" ptype="SM_LIGHT" className="ml-2" />
                </Link>
                <Link to="mailto:email@mail.com" type="external" className="flex hover:text-white transition-colors duration-300 font-normal text-aths-special">
                  <Email />
                  <Text text="Mail us" ptype="SM_BOLD" type="P" className="ml-2" />
                </Link>
              </div>
              <div className="flex md:self-start">
                <Link to="twitter.com" type="external" className="mr-4">
                  <Twitter />
                </Link>
                <Link to="facebook.com" type="external" className="mr-4">
                  <Facebook />
                </Link>
                <Link to="youtube.com" type="external">
                  <Youtube />
                </Link>
              </div>
            </div>
          </div>
        </Wrapper>
      </section>

      <section className="bg-granite-green py-5">
        <Wrapper>
          <div className="lg:px-0 mx-auto w-full grid lg:grid-cols-2 grid-cols-1 justify-between">
            <Text type="P" ptype="SM_LIGHT" className="text-sm font-normal text-aths-special">© ACCESS AFRICA DIRECT {year} &nbsp;&nbsp; All Rights Reserved.</Text>
            <div className="lg:justify-self-end lg:mr-24">
              <Link type="internal" to="#" className="font-light text-sm hover:text-white transition-colors duration-300">Imprint |</Link>
              <Link type="internal" to="#" className="font-light text-sm hover:text-white transition-colors duration-300"> Acceptable Use Policy | </Link>
              <Link type="internal" to="#" className="font-light text-sm hover:text-white transition-colors duration-300"> Privacy Policy | </Link>
              <Link type="internal" to="#" className="font-light text-sm hover:text-white transition-colors duration-300">T's &amp; C's</Link>
            </div>
          </div>
        </Wrapper>
      </section>
    </footer>
  )
}

export default FooterStandalone