import React from 'react';

const MenuDownArrow: React.FC = () => {
  return (
    <svg width="15px" height="8px" viewBox="0 0 15 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/menu-down-arrow/orange</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Top-navigation--Language-component" transform="translate(-1145.000000, -54.000000)" fill="#000000" fillRule="nonzero">
              <g id="Group" transform="translate(1145.000000, 54.000000)">
                  <g id="down-arrow" transform="translate(0.000000, 0.000000)">
                      <path d="M14.2240572,0.180450802 C13.9894714,-0.0601502675 13.6102699,-0.0601502675 13.3756841,0.180450802 L7.19998418,6.51448094 L1.02431234,0.180450802 C0.78972658,-0.0601502675 0.410525075,-0.0601502675 0.175939318,0.180450802 C-0.0586464392,0.421051872 -0.0586464392,0.809976969 0.175939318,1.05057804 L6.77581173,7.81968621 C6.8928093,7.93968387 7.04639674,8 7.2000123,8 C7.35362787,8 7.5072153,7.93968387 7.62421288,7.81968621 L14.2240853,1.05057804 C14.4586429,0.809976969 14.4586429,0.421051872 14.2240572,0.180450802 Z" id="Path"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default MenuDownArrow;
