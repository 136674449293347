/* eslint-disable @typescript-eslint/no-unused-vars */
const atta = '../../../../atta.3837bc0f.png'
const sa = '../../../../SA.png'
const zimbabwe = '../../../../Zimbabwe_logo.png'
const kenya = '../../../../kenya.png'
const botswana = '../../../../Botswana_logo.png'
const madagascar = '../../../../Madagascar_logo.png'
const malawi = '../../../../Malawi_logo.png'
const nambia = '../../../../Namibia_logo.png'
const uganda = '../../../../Uganda_logo.png'
const zambia = '../../../../Zambia_logo.png'

export const data = {
  footerLists: [
    {
      title: 'Destination',
      links: [
        { text: 'Full country list', to: '/' },
        { text: 'Region overview', to: '/' }
      ]
    },
    {
      title: 'Top destinations',
      links: [
        { text: 'Kruger National Park', to: '/' },
        { text: 'Cape Town', to: '/' },
        { text: 'Victoria Falls', to: '/' },
        { text: 'Okavango Delta', to: '/' },
        { text: 'Serengeti', to: '/' },
        { text: 'Masai Mara', to: '/' }
      ]
    },
    {
      title: 'About',
      links: [
        { text: 'Why book with us', to: '/' },
        { text: 'How do we work', to: '/' },
        { text: 'Who we are', to: '/' }
      ]
    },
    {
      title: 'Everything else',
      links: [
        { text: 'Subscribe to our newsletter', to: '/' },
        { text: 'Update my details', to: '/' },
        { text: 'Login to my profile', to: '/' }
      ]
    }
  ],
  imageData: [
    { src: sa, alt: 'SA', },
    { src: malawi, alt: 'Malawi' },
    { src: botswana, alt: 'Botswana' },
    { src: nambia, alt: 'Nambia', },
    { src: kenya, alt: 'Kenya' },
    { src: uganda, alt: 'Uganda' },
    { src: madagascar, alt: 'Madagascar', },
    { src: zambia, alt: 'Zambia', },
    { src: zimbabwe, alt: 'Zimbabwe' },
    { src: atta, alt: 'Atta' },
  ]
}
